// import dependencies
import React from "react";

// import components
import Layout from "../components/layout";
import PostNavButtons from "../components/posts-nav-buttons";
import Section from "../components/section";

// import styles and assets
import * as styles from "./styles/group-feed.module.scss";

const NotFoundPage = () => {
    return (
        <Layout>
            <Section>
                <PostNavButtons />
                <div className={styles.groupContainer}>
                    <h1>Sorry, there's nothing around here</h1>
                    <h4>
                        Remember to look elsewhere to find whatever you're
                        looking for, maybe you'll find a surprise, something
                        you'll like perhaps, who knows.
                    </h4>
                </div>
            </Section>
        </Layout>
    );
};
export default NotFoundPage;
